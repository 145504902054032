import { Select, Tag, Table, message } from 'antd'
import { useWindowSize } from '../../../../windowResize'
import axios from 'axios'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { strapiURL } from '../../../config'
import {
  restructureJob,
  usDateFormat,
  dateFormatHandler,
  permissionsHandler,
} from '../../../functions'
import CoverLetterModal from '../../Modals/coverLetterModal'
import { useSelector } from 'react-redux'

const ApplicationHistoryComponent = ({ userProfile, user }) => {
  let _windowSize = useWindowSize()
  const { Option } = Select
  const [applications, setApplications] = useState([])
  const [currentApplications, setCurrentApplications] = useState([])
  const [loading, setLoading] = useState(true)
  const [sortValue, setSortValue] = useState('')
  const [filterValue, setFilterValue] = useState('all')
  const [coverLetter, setCoverLetter] = useState('')
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)

  const storeProducts = useSelector(state => state.user).products
  let products = permissionsHandler(storeProducts, user?.role?.id)
  let isAllowed = products?.subscriptionPurchased
  let largeScreen = _windowSize?.width && _windowSize?.width > 1023

  useEffect(() => {
    const filterBy = getFilterBy()
    axios
      .get(
        strapiURL +
          `/candidate-profiles/${userProfile?.id}/applicationReview?page=${page}&sortBy=${sortValue}&filterBy=${filterBy}`
      )
      .then(res => {
        let _copyData = [...(res.data.applications || [])]
        _copyData.forEach((application, index) => {
          application.key = index
          application.job = restructureJob(application.job)
        })
        setApplications(_copyData)
        setCurrentApplications(_copyData)
        setTotal(res.data.total)
        setPageSize(res.data.pageSize)
      })
      .catch(err => {
        message.error('Something went wrong')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [page, sortValue, filterValue])

  const onChangePage = page => {
    setPage(page)
  }

  const viewResume = (base64, fileName) => {
    if (typeof window !== 'undefined') {
      let a = document.createElement('a')
      a.href = 'data:application/octet-stream;base64,' + base64
      a.download = fileName
      a.click()
      a.remove()
    }
  }

  const getFilterBy = () => {
    if (filterValue === 'month') {
      return moment().subtract(1, 'months').unix() * 1000
    }

    if (filterValue === 'three-months') {
      return moment().subtract(3, 'months').unix() * 1000
    }

    if (filterValue === 'six-months') {
      return moment().subtract(6, 'months').unix() * 1000
    }
    return ''
  }

  const applicationCols = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record, index) => {
        return (
          <div className="flex items-center">
            {user && isAllowed && (
              <div className="h-10 w-10 rounded shadow flex items-center justify-center">
                <div
                  className="h-8 w-8 bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url(${
                      record?.employer_image || '/icons/icon-medical.png'
                    } )`,
                  }}
                />
              </div>
            )}
            <div className="ml-2">
              <a
                href={`/job-detail/${record?.job_slug}`}
                className="text-xs font-bold font-sans mb-0.5 cursor-pointer text-black"
                target="_blank"
              >
                {record?.job_title}
              </a>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (text, record, index) => {
        return (
          <div className="flex items-center ">
            <img src="/icons/icon-location-pin.svg" className="mb-0" />
            <span className="text-last text-location leading-0 ml-1">
              {record.job_location_city}
            </span>
          </div>
        )
      },
      responsive: ['lg'],
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      render: (text, record, index) => {
        return (
          <div>
            <p className="text-location text-last font-sans mb-0">
              {record?.employer_name || 'Not Specified'}
            </p>
          </div>
        )
      },
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry',
      key: 'expiry',
      render: (text, record, index) => {
        return (
          <div>
            <p className="text-location text-last font-sans mb-0">
              {record.expiry_date && dateFormatHandler(record.expiry_date)}
            </p>
          </div>
        )
      },
    },
    {
      title: 'Submitted Resume',
      dataIndex: 'submitted',
      key: 'submitted',
      render: (text, record, index) => {
        return (
          <div>
            <span
              onClick={() =>
                viewResume(record?.parsed_resume, record?.resume_name)
              }
              target="_blank"
              className="text-[#01B3E3] text-last font-sans mb-0 underline cursor-pointer"
            >
              {record?.resume_name}
            </span>
          </div>
        )
      },
      responsive: ['lg'],
    },
    {
      title: 'Cover Letter ',
      dataIndex: 'cover_letter',
      key: 'cover_letter',
      render: (text, record, index) => {
        let _coverLetter = record?.cover_letter_text
        return (
          <>
            <div className="flex items-center">
              <div
                className="h-10 w-10 rounded-full border border-[#C4C4C4] flex items-center bg-[#EAEAEA] justify-center cursor-pointer"
                onClick={() => {
                  if (_coverLetter?.length) {
                    setCoverLetter(record)
                  } else {
                    message.info('No cover letter for this job.')
                  }
                }}
              >
                <span className="text-tagSize text-[#C4C4C4]">Preview</span>
              </div>
              <div className="ml-2">
                <p className="text-last font-sans mb-0">
                  {record?.cover_letter_name}
                </p>
              </div>
            </div>
            {_coverLetter?.length ? (
              <CoverLetterModal
                modalOpen={coverLetter}
                openCoverLetter={setCoverLetter}
                record={coverLetter}
              />
            ) : (
              <></>
            )}
          </>
        )
      },
      responsive: ['lg'],
    },
    {
      title: 'Date Applied',
      dataIndex: 'date',
      key: 'date',
      render: (text, record, index) => {
        return (
          <Tag className="rounded text-[#01B3E3]" color="#EEF7FA">
            {usDateFormat(
              new Date(record.applied_at).toISOString().split('T')[0]
            )}
          </Tag>
        )
      },
    },
  ]
  const applicationColsMobile = [
    {
      title: '',
      dataIndex: 'mobile',
      key: 'mobile',
      width: '100%',
      render: (text, record, index) => {
        let _job = restructureJob(record?.job)

        return (
          <div className="flex flex-col mx-[-16px]">
            <div className="flex w-full">
              {user && isAllowed && (
                <div
                  className="h-12 w-12 bg-cover bg-center bg-no-repeat rounded shadow-lg"
                  style={{
                    backgroundImage: `url(${
                      record?.employer_image || '/icons/icon-medical.png'
                    } )`,
                  }}
                />
              )}
              <div className="ml-2">
                <div className="flex flex-col">
                  <p className="text-[12px] text-location font-semibold leading-0 m-0">
                    Title
                  </p>
                  <p className="text-[13px] font-bold font-sans mb-0.5 leading-0">
                    {record?.job_title}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 grid grid-cols-3 gap-4">
              <div className="flex flex-col overflow-hidden">
                <p className="text-[12px] text-location leading-0 m-0 font-semibold pb-1">
                  Location
                </p>
                <div className="flex items-center ">
                  <img src="/icons/icon-location-pin.svg" className="mb-0" />
                  <span className="text-[11px] text-location leading-0 ml-1">
                    {record?.job_location_city}
                  </span>
                </div>
              </div>
              <div className="flex flex-col overflow-hidden">
                <p className="text-[12px] text-location leading-0 m-0 font-semibold pb-1">
                  Company
                </p>
                <div className="flex items-center">
                  <span className="text-[11px] text-location leading-0 ml-1">
                    {record?.employer_name?.length > 17
                      ? record?.employer_name?.substring(0, 17).concat('...')
                      : record?.employer_name || 'Not Specified'}
                  </span>
                </div>
              </div>
              <div className="flex flex-col overflow-hidden">
                <p className="text-[12px] text-location leading-0 m-0 font-semibold pb-1">
                  Date Applied
                </p>
                <div className="flex items-center">
                  <Tag className="rounded text-[#01B3E3]" color="#EEF7FA">
                    {usDateFormat(
                      new Date(record.applied_at).toISOString().split('T')[0]
                    )}
                  </Tag>
                </div>
              </div>
              <div className="flex flex-col overflow-hidden">
                <p className="text-[12px] text-location leading-0 m-0 font-semibold pb-1">
                  Expiry date
                </p>
                <div className="flex items-center">
                  <div>
                    <p className="text-location text-last font-sans mb-0">
                      {dateFormatHandler(record.expiry_date)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col overflow-hidden">
                <p className="text-[12px] text-location leading-0 m-0 font-semibold pb-1">
                  Resume
                </p>
                <div className="flex items-center">
                  <span
                    onClick={() =>
                      viewResume(record?.parsed_resume, record?.resume_name)
                    }
                    target="_blank"
                    className="text-[#01B3E3] text-last font-sans mb-0 underline cursor-pointer"
                  >
                    {record?.resume_name?.length > 15
                      ? record?.resume_name.substring(0, 15).concat('...')
                      : record?.resume_name || ''}
                  </span>
                </div>
              </div>
              <div className="flex flex-col overflow-hidden">
                <p className="text-[12px] text-location leading-0 m-0 font-semibold pb-1">
                  Cover Letter
                </p>
                <div className="flex items-center">
                  <button
                    className="text-[#01B3E3] text-last font-sans mb-0 underline cursor-pointer"
                    onClick={() => {
                      if (record?.cover_letter_text?.length) {
                        setCoverLetter(record)
                      } else {
                        message.info('No cover letter for this job.')
                      }
                    }}
                  >
                    <span className="text-last">Preview</span>
                  </button>
                  <div className="ml-2">
                    <p className="text-last font-sans mb-0">
                      {record?.cover_letter_name}
                    </p>
                  </div>
                </div>
                {record?.cover_letter_text?.length ? (
                  <CoverLetterModal
                    modalOpen={coverLetter}
                    openCoverLetter={setCoverLetter}
                    record={coverLetter}
                  />
                ) : (
                  <></>
                )}{' '}
              </div>
            </div>
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <div className="container mx-auto">
        <div className="px-6 lg:px-0 rounded-lg bg-white mt-4">
          <div className="flex flex-col lg:flex-row py-4 border-b border-bgSecondaryButton justify-between">
            <div className="flex items-center">
              <img src="/icons/icon-application.svg" className="mb-0" />
              <h1 className="font-playfair text-2xl lg:text-xl font-normal mb-0 ml-4">
                Application History
              </h1>
            </div>
            <div className="flex items-center mt-3 lg:mt-0 application-history-select">
              <div
                className={`relative border border-[#C4C4C4] rounded focus:border-merlot px-2 py-1 sort-select ${
                  largeScreen ? 'sort-value' : ''
                }`}
              >
                <div>
                  <span
                    className={`${
                      sortValue ? 'hidden lg:block' : 'block'
                    } absolute z-10 font-sans font-semibold text-[#6A6A6A] mt-1`}
                  >
                    Sort By:
                  </span>
                </div>

                <Select
                  defaultValue={sortValue}
                  onChange={val => {
                    setSortValue(val)
                    setPage(1)
                  }}
                  className="bg-transparent z-20 relative w-full"
                >
                  <Option value="job_title">Title</Option>
                  <Option value="job_location_city">Location</Option>
                  <Option value="employer_name">Company</Option>
                  <Option value="expiry_date">Expiry Date</Option>
                  <Option value="applied_at">Date Applied</Option>
                </Select>
              </div>
              <div className="relative border border-[#C4C4C4] rounded focus:border-merlot px-2 py-1 sort-select ml-3 filter-select">
                <Select
                  defaultValue={filterValue}
                  onChange={val => {
                    setFilterValue(val)
                    setPage(1)
                  }}
                  className="bg-transparent z-20 relative w-full"
                >
                  <Option value="all">All</Option>
                  <Option value="month">Last Month</Option>
                  <Option value="three-months">Last 3 Months</Option>
                  <Option value="six-months">Last 6 Months</Option>
                </Select>
              </div>
            </div>
          </div>
          {loading ? (
            <div
              className="w-full min-h-700px bg-no-repeat bg-cover"
              style={{
                backgroundImage: `url(/loaders/application-history-loader.png)`,
              }}
            />
          ) : (
            <div className="w-full">
              <Table
                className="hide-table-header-background hide-table-header-cols-border table-with-pagination"
                columns={largeScreen ? applicationCols : applicationColsMobile}
                dataSource={currentApplications}
                bordered={false}
                pagination={{
                  current: page,
                  pageSize: pageSize,
                  onChange: onChangePage,
                  total,
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default ApplicationHistoryComponent
